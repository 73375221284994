import type { FC } from 'react';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Scrollbar from 'src/Components/Scrollbar';
import Section from './section';
import { Box } from '@mui/material';
import { t } from '@lingui/macro';
import { MemberRoles } from 'src/models/memberRoles';
import useSiteId from 'src/hooks/useSiteId';
import useOrganizationId from 'src/hooks/useOrganizationId';
import useGroupId from 'src/hooks/useGroupId';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOrganizationRole } from 'src/hooks/use-role';
import { useSelector } from 'src/store';
import { getFirstSiteId } from '../navbar';

interface DashboardSidebarProps {
  onClose: () => void;
  isOpen: boolean;
}

const userSections = (organizationId, siteId, groupId) => [
  {
    title: t`Display data`,
    items: [
      {
        title: t`Map`,
        path: `/${organizationId}/${siteId}/${groupId}/map`,
        icon: <FontAwesomeIcon icon={['far', 'map-location-dot']} />
      },
      {
        title: t`Cross tables`,
        path: `/${organizationId}/${siteId}/${groupId}/table`,
        icon: <FontAwesomeIcon icon={['far', 'table-cells']} />
      },
      {
        title: t`Manage data`,
        path: `/${organizationId}/${siteId}/${groupId}/data`,
        icon: <FontAwesomeIcon icon={['far', 'database']} />
      }
    ]
  }
];

const adminSections = (organizationId, siteId, groupId) => [
  {
    title: t`Display data`,
    items: [
      {
        title: t`Map`,
        path: `/${organizationId}/${siteId}/${groupId}/map`,
        icon: <FontAwesomeIcon icon={['far', 'map-location-dot']} />
      },
      {
        title: t`Measurements`,
        path: `/${organizationId}/${siteId}/${groupId}/table`,
        icon: <FontAwesomeIcon icon={['far', 'table-cells']} />
      }
    ]
  },
  {
    title: t`Administrator`,
    items: [
      {
        title: t`Points and parameters`,
        path: `/${organizationId}/${siteId}/${groupId}/data`,
        icon: <FontAwesomeIcon icon={['far', 'database']} />
      },
      {
        title: t`Import measurements`,
        path: `/${organizationId}/${siteId}/import`,
        icon: <FontAwesomeIcon icon={['far', 'down-to-dotted-line']} />
      },
      {
        title: t`Manage groups`,
        path: `/${organizationId}/${siteId}/group`,
        icon: <FontAwesomeIcon icon={['far', 'object-intersect']} />
      },
      {
        title: t`Members`,
        path: `/${organizationId}/members`,
        icon: <FontAwesomeIcon icon={['far', 'users']} />
      }
    ]
  }
];

const DashboardSidebar: FC<DashboardSidebarProps> = ({ onClose, isOpen }) => {
  const location = useLocation();
  const organizationId = useOrganizationId();

  const data = useSelector((state) => state.organization.organizations);

  const siteId = useSiteId() || (organizationId && getFirstSiteId(data, organizationId));
  const groupId = useGroupId() || 0;

  const role = useOrganizationRole();

  const sections = useMemo(() => {
    switch (role) {
      case MemberRoles.Administrator:
        return adminSections(organizationId, siteId, groupId);
      case MemberRoles.User:
        return userSections(organizationId, siteId, groupId);
      default:
        return [];
    }
  }, [role, organizationId, groupId, siteId]);

  if (sections.length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          borderRight: '1px solid #e0e0e0'
        }}
      >
        <Scrollbar options={{ suppressScrollX: true }}>
          <Box sx={{ p: 2 }}>
            <Section
              title=""
              items={[
                { loading: true, title: 'Loading...' },
                { loading: true, title: 'Loading...' },
                { loading: true, title: 'Loading...' },
                { loading: true, title: 'Loading...' }
              ]}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  borderTop: '1px solid #e0e0e0'
                }
              }}
            />
          </Box>
        </Scrollbar>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        borderRight: '1px solid #e0e0e0'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <Section
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  borderTop: '1px solid #e0e0e0'
                }
              }}
              {...section}
            />
          ))}
        </Box>
      </Scrollbar>
    </Box>
  );
};

export default DashboardSidebar;
