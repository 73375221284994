import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TableViewMode } from '../pages/member/table';

interface TableNavigationSate {
  selectedView: 'allData' | 'parameter' | 'point' | 'stat' | 'date';
  selectedParameter: string;
  selectedPoint: string;
  selectedAggregation: 'min' | 'max' | 'average';
  selectedDateGrouping: 'day' | 'week' | 'month' | 'year';
}

const initialState: TableNavigationSate = {
  selectedView: 'stat',
  selectedParameter: '',
  selectedPoint: '',
  selectedAggregation: 'average',
  selectedDateGrouping: 'day'
};

const slice = createSlice({
  name: 'tableNavigation',
  initialState,
  reducers: {
    setSelectedView: (state, action: PayloadAction<TableViewMode>) => {
      state.selectedView = action.payload;
    },
    setSelectedParameter: (state, action: PayloadAction<string>) => {
      state.selectedParameter = action.payload;
    },
    setSelectedPoint: (state, action: PayloadAction<string>) => {
      state.selectedPoint = action.payload;
    },
    setSelectedAggregation: (state, action: PayloadAction<'min' | 'max' | 'average'>) => {
      state.selectedAggregation = action.payload;
    },
    setSelectedDateGrouping: (state, action: PayloadAction<'day' | 'week' | 'month' | 'year'>) => {
      state.selectedDateGrouping = action.payload;
    }
  }
});
export const {
  setSelectedView,
  setSelectedDateGrouping,
  setSelectedAggregation,
  setSelectedPoint,
  setSelectedParameter
} = slice.actions;

export const { reducer } = slice;
