// Common
import { request } from '../../common';

// Models
import { OrganizationMemberDto } from './models/OrganizationMemberDto';

interface RequestQuery {
  organizationId?: number;
}

export const getOrganizationMembers = (query: RequestQuery): Promise<Response> => {
  const queryString = query.organizationId ? `?organizationId=${query.organizationId}` : '';

  return request({
    method: 'GET',
    api: `${process.env.REACT_APP_API_URL}/api/members` + queryString,
    authorized: true
  });
};

interface Response {
  members: OrganizationMemberDto[];
}
