// API
import api from './api';

// Externals
import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

// Models
import { PermissionDto } from 'src/models/permissionDto';

//***************************************  STATE  ****************************************//

export interface PermissionState {}

const initialState: PermissionState = {};

//**********************************  REQUEST & RESULT  **********************************//

interface PermissionResult {
  permissions: PermissionDto[];
}

interface RolePermissionResult {
  permissions: string[];
}

interface RolePermissionRequest {
  roleId: string;
}

interface UpdatePermissionRequest {
  roleId: string;
  permissions: string[];
}

//*****************************************  API  *****************************************//

export const extendedPermissionAPI = api.enhanceEndpoints({ addTagTypes: ['permissions'] }).injectEndpoints({
  endpoints: (builder) => ({
    getPermissions: builder.query<PermissionResult, void>({
      query: (args) => ({
        url: '/permissions',
        method: 'GET'
      }),
      transformResponse: (response: { permissions: string[] }) => {
        return {
          permissions: _.map<PermissionDto>(response.permissions, (p) => {
            const key = p;
            const category = p.split('.')[1];
            const name = p.split('.')[2];

            return { category, key, name };
          })
        };
      }
    }),
    getRolePermissions: builder.query<RolePermissionResult, RolePermissionRequest>({
      query: (args) => ({
        url: `/roles/${args.roleId}/permissions`,
        method: 'GET'
      }),
      providesTags: ['permissions']
    }),
    updatePermission: builder.mutation<void, UpdatePermissionRequest>({
      query: (args) => ({
        url: `/roles/${args.roleId}/permissions`,
        method: 'PUT',
        body: args
      }),
      invalidatesTags: ['permissions']
    })
  })
});

//****************************************  SLICES  ****************************************//

const slice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {}
});

//****************************************  THUNKS  ****************************************//

//****************************************  EXPORT  ****************************************//

export const { reducer } = slice;

export const { useGetPermissionsQuery, useGetRolePermissionsQuery, useUpdatePermissionMutation } =
  extendedPermissionAPI;
