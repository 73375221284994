import type { FC } from 'react';
import { useMemo } from 'react';

// Components
import Scrollbar from 'src/Components/Scrollbar';
import Section from './section';

// Externals
import { Box, Drawer } from '@mui/material';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import { t } from '@lingui/macro';

const AdministrationSidebar: FC = () => {
  const location = useLocation();

  const sections = useMemo(
    () => [
      {
        title: t`Administration`,
        items: [
          {
            title: t`Organizations`,
            path: '/administration/organizations',
            icon: <HomeWorkIcon />
          }
          // {
          //   title: t`Roles`,
          //   path: '/administration/roles',
          //   icon: <PeopleAltSharpIcon />
          // }
        ]
      }
    ],
    []
  );

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <Section
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...section}
            />
          ))}
        </Box>
      </Scrollbar>
    </Box>
  );

  return (
    <Drawer
      anchor="left"
      open
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          height: 'calc(100% - 65px) !important',
          top: '64px !Important',
          width: 280
        }
      }}
      variant="permanent"
    >
      {content}
    </Drawer>
  );
};

AdministrationSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default AdministrationSidebar;
