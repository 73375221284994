import React, { lazy, Suspense } from 'react';

import AuthGuard from './Components/guards/auth-guard';
import GuestGuard from './Components/guards/guest-guard';
import { RouteSiteGuard } from './pages/member/guards/route-site-guard';
import AdministrationLayout from './layouts/administration-layout';
import DashboardLayout from './layouts/dashboard-layout';
import EmptyLayout from './layouts/empty-layout';
import LoadingScreen from './screens/LoadingScreen';

import SignIn from './pages/auth/signin';
import SignUp from './pages/auth/signup';
import DefaultSiteRedirection from './pages/member/default-site-redirection';
import { MemberRoles } from './models/memberRoles';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Admin Pages
const AdminHomePage = Loadable(lazy(() => import('./pages/admin/home')));
const AdminOrganizations = Loadable(lazy(() => import('./pages/admin/organizations')));
const AdminOrganizationDetails = Loadable(lazy(() => import('./pages/admin/organizations/details')));
const AdminSite = Loadable(lazy(() => import('./pages/admin/sites/details')));
//const AdminRoles = Loadable(lazy(() => import('./pages/admin/roles')));
//const AdminRolePermissions = Loadable(lazy(() => import('./pages/admin/permissions')));

// General Pages
const OrganizationMemberSiteData = Loadable(lazy(() => import('./pages/member/sites/data')));
const OrganizationMemberSiteGroups = Loadable(lazy(() => import('./pages/member/sites/groups')));
const OrganizationMemberSiteMembers = Loadable(lazy(() => import('./pages/member/sites/members')));
const OrganizationMemberSiteImportData = Loadable(lazy(() => import('./pages/member/sites/import-data')));
const OrganizationMemberSiteGroupDetails = Loadable(lazy(() => import('./pages/member/groups/edit')));
const OrganizationMemberSiteTable = Loadable(lazy(() => import('./pages/member/table')));
const OrganizationMemberSiteMap = Loadable(lazy(() => import('./pages/member/map')));

// Error Pages
const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

const routes = [
  {
    path: '/',
    element: (
      <AuthGuard role={MemberRoles.User}>
        <RouteSiteGuard>
          <DashboardLayout />
        </RouteSiteGuard>
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <DefaultSiteRedirection />
      },

      // deprecated beacuse the new route is /:organizationId/members
      // {
      //   path: ':organizationId/manage',
      //   element: <MemberOrganizationDetails />
      // },
      {
        path: ':organizationId/members',
        element: <OrganizationMemberSiteMembers />
      },
      {
        path: ':organizationId/:siteId/import',
        element: <OrganizationMemberSiteImportData />
      },
      {
        path: ':organizationId/:siteId/group',
        element: <OrganizationMemberSiteGroups />
      },
      {
        path: ':organizationId/:siteId/group/:selectedGroupId',
        element: <OrganizationMemberSiteGroupDetails />
      },
      {
        path: ':organizationId/:siteId/:groupId/table',
        element: <OrganizationMemberSiteTable />
      },
      {
        path: ':organizationId/:siteId/:groupId/map/',
        element: <OrganizationMemberSiteMap />
      },
      {
        path: ':organizationId/:siteId/:groupId/data',
        element: <OrganizationMemberSiteData />
      }
    ]
  },
  {
    path: '/administration',
    element: (
      <AuthGuard role={MemberRoles.Administrator}>
        <AdministrationLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <AdminHomePage />
      },
      {
        path: 'organizations',
        children: [
          {
            path: '',
            element: <AdminOrganizations />
          },
          {
            path: ':organizationId',
            element: <AdminOrganizationDetails />
          },
          {
            path: ':organizationId/:siteId',
            element: <AdminSite />
          },
          {
            path: ':organizationId/members',
            element: <AdminOrganizationDetails />
          }
        ]
      }
    ]
  },

  {
    path: '/auth',
    element: <EmptyLayout />,
    children: [
      {
        path: 'signin',
        element: (
          <GuestGuard>
            <SignIn />
          </GuestGuard>
        )
      },
      {
        path: 'signup',
        element: (
          <GuestGuard>
            <SignUp />
          </GuestGuard>
        )
      }
    ]
  },
  {
    path: '*',
    children: [
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
