import { FC, useState } from 'react';

// Externals
import { IconButton, Popover } from '@mui/material';

// Components
import NavOptionsContent from './content';
import { AccountCircle } from '@mui/icons-material';

const NavOptions: FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton onClick={handleOpen}>
        <AccountCircle sx={{ color: 'white', width: 30, height: 30 }} />
      </IconButton>

      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <NavOptionsContent />
      </Popover>
    </>
  );
};

export default NavOptions;
