import { useSelector } from 'src/store';
import useOrganizationId from './useOrganizationId';
import useSiteId from './useSiteId';

export const useOrganizationRole = (): 'user' | 'administrator' | 'unknown' => {
  const organizationId = useOrganizationId();

  const data = useSelector((state) => state.organization.organizations);
  if (!data || !data.organizations) {
    throw new Error('this can only be used in the context of an initialized state with known organizations');
  }

  const role = data.organizations.find((org) => org.id === organizationId)?.role || 'unknown';

  return role;
};

export const useOrganizationName = (): string => {
  const organizationId = useOrganizationId();

  const data = useSelector((state) => state.organization.organizations);
  if (!data || !data.organizations) {
    throw new Error('this can only be used in the context of an initialized state with known organizations');
  }

  return data.organizations.find((org) => org.id === organizationId)?.name;
};

export const useSiteRole = (): 'user' | 'guest' | 'unknown' => {
  const organizationId = useOrganizationId();
  const siteId = useSiteId();

  const data = useSelector((state) => state.organization.organizations);
  if (!data || !data.organizations) {
    throw new Error('this can only be used in the context of an initialized state with known organizations');
  }

  const role =
    data.organizations?.find((org) => org.id === organizationId)?.sites?.find((site) => site.id === siteId)?.role ||
    'unknown';

  return role;
};


export const useSiteName = (): string => {
  const organizationId = useOrganizationId();
  const siteId = useSiteId();

  const data = useSelector((state) => state.organization.organizations);
  if (!data || !data.organizations) {
    throw new Error('this can only be used in the context of an initialized state with known organizations');
  }

  return data.organizations?.find((org) => org.id === organizationId)?.sites?.find((site) => site.id === siteId)?.name;
};
