/* eslint-disable no-loop-func */
import { FC, useEffect } from 'react';
import { useRoutes } from 'react-router-dom';

// CSS
import './App.css';

// Externals
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { Toaster } from 'react-hot-toast';

// Routes
import routes from './routes';

// Screens
import SplashScreen from './screens/SplashScreen';

// Slices
import { initialize } from './slices/authentication';

// Stores
import { useDispatch, useSelector } from './store';

import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/pro-regular-svg-icons';

library.add(far);

const App: FC = () => {
  return (
    <ThemeProvider
      theme={createTheme({
        palette: {
          primary: {
            main: '#057FA8'
          }
        }
      })}
    >
      <CssBaseline />
      <Toaster position="bottom-center" />
      <Content />
    </ThemeProvider>
  );
};

const Content: FC = () => {
  const dispatch = useDispatch();
  const content = useRoutes(routes);

  const isInitialized = useSelector<boolean>((state) => state.authentication.isInitialized);

  useEffect(() => {
    dispatch(initialize());
  }, [dispatch]);

  if (!isInitialized) return <SplashScreen />;
  return content;
};

export default App;
