import * as React from 'react';

// Externals
import { LockOutlined } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Checkbox,
  Container,
  CssBaseline,
  Link,
  FormControlLabel,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';

// Slices
import { useRegisterMutation } from 'src/slices/authentication';
import { Trans, t } from '@lingui/macro';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.krigis.com/">
        Krigis
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function SignUp() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [register] = useRegisterMutation();

  const [accepted, setAccepted] = React.useState<boolean>(false);
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);

    try {
      await register({ email: data.get('email').toString(), password: data.get('password').toString() }).unwrap();

      navigate(`/auth/signin?redirect=${encodeURIComponent(searchParams.get('redirect') || '/')}`);
    } catch (err) {
      switch (err.data.type) {
        case 'email-already-used':
          toast.error(t`Email already used`);
          break;
        case 'user-register-invalid-password-format':
          toast.error(t`The provided password doesn't respect our security scheme.`);
          break;
        case 'user-register-invalid':
          toast.error(t`Something went wrong with the register.`);
          break;
        default:
          toast.error(err?.data?.message || t`Something went wrong.`);
          break;
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          <Trans>Sign up</Trans>
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="given-name"
                name="firstName"
                required
                fullWidth
                id="firstName"
                label={t`First Name`}
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="lastName"
                label={t`Last Name`}
                name="lastName"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField required fullWidth id="email" label={t`Email Address`} name="email" autoComplete="email" />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label={t`Password`}
                type="password"
                id="password"
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    value="accepted"
                    color="primary"
                    checked={accepted}
                    onChange={() => setAccepted((value) => !value)}
                  />
                }
                label={t`I accept the terms of use.`}
              />
            </Grid>
          </Grid>
          <LoadingButton
            loading={submitting}
            disabled={!accepted || submitting}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            <Trans>Sign Up</Trans>
          </LoadingButton>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link
                href={`/auth/signin?redirect=${encodeURIComponent(searchParams.get('redirect') || '/')}`}
                variant="body2"
              >
                <Trans>Already have an account? Sign in</Trans>
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 5 }} />
    </Container>
  );
}
