import { Navigate } from 'react-router-dom';
import { useSelector } from 'src/store';

const DefaultSiteRedirection = () => {
  const data = useSelector((state) => state.organization.organizations);
  const organizations = data?.organizations;

  const userId = useSelector((state) => {
    return state.authentication.user.id;
  });

  const lastOrganizationId = Number(window.localStorage.getItem(`${userId}-organization`));
  const lastSiteId = Number(window.localStorage.getItem(`${userId}-site`));
  const lastGroupId = window.localStorage.getItem(`${userId}-group`);

  const foundOrganization = organizations.find((organization) => organization.id === lastOrganizationId);
  const foundSite = foundOrganization?.sites.find((site) => site.id === lastSiteId);
  const foundGroup = lastGroupId === '0' || foundSite?.groups.find((g) => g.id === lastGroupId);

  if (foundOrganization && foundSite && foundGroup) {
    return <Navigate replace={true} to={`/${lastOrganizationId}/${lastSiteId}/${lastGroupId}/map`} />;
  }

  const defaultOrganization = organizations.length && organizations[0];
  const defaultSite = defaultOrganization?.sites.length && defaultOrganization?.sites[0];
  const defaultGroup =
    defaultSite?.role === 'user' ? { id: '0' } : defaultSite?.groups.length && defaultSite?.groups[0];

  if (defaultOrganization && defaultSite && defaultGroup) {
    window.localStorage.setItem(`${userId}-organization`, String(defaultOrganization.id));
    window.localStorage.setItem(`${userId}-site`, String(defaultSite.id));
    window.localStorage.setItem(`${userId}-group`, String(defaultGroup.id));
    return <Navigate replace={true} to={`/${defaultOrganization.id}/${defaultSite.id}/${defaultGroup.id}/map`} />;
  }

  return <Navigate replace={true} to={`404`} />;
};

export default DefaultSiteRedirection;
