import * as React from 'react';

// Externals
import { LockOutlined } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Checkbox,
  Container,
  CssBaseline,
  Link,
  FormControlLabel,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';

// Slices
import { useLoginMutation } from 'src/slices/authentication';
import { Trans, t } from '@lingui/macro';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.krigis.com/">
        Krigis
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function SignIn() {
  const [searchParams] = useSearchParams();

  const [login] = useLoginMutation();

  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setSubmitting(true);

    event.preventDefault();

    const data = new FormData(event.currentTarget);

    try {
      await login({ email: data.get('email').toString(), password: data.get('password').toString() }).unwrap();
    } catch (err) {
      toast.error(t`Your username and password do not match.`);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          <Trans>Sign in</Trans>
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label={t`Email Address`}
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label={t`Password`}
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <FormControlLabel control={<Checkbox value="remember" color="primary" />} label={t`Remember me`} />
          <LoadingButton
            loading={submitting}
            disabled={submitting}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            <Trans>Sign In</Trans>
          </LoadingButton>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                <Trans>Forgot password?</Trans>
              </Link>
            </Grid>
            <Grid item>
              <Link
                href={`/auth/signup?redirect=${encodeURIComponent(searchParams.get('redirect') || '/')}`}
                variant="body2"
              >
                <Trans>Don't have an account? Sign Up</Trans>
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
