import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type MagnitudePrecision = 'full' | 'half' | 'third';

interface KrigingState {
  scale: 'log' | 'linear';
  valueSelection: 'min' | 'max' | 'avg' | 'last';
  alpha: number;
  model: 'gaussian' | 'spherical' | 'exponential' | 'linear';
  variance: number;
  pixelSize: number;
  precision: MagnitudePrecision;
}

const initialState: KrigingState = {
  scale: 'log',
  valueSelection: 'last',
  model: 'linear',
  variance: 0,
  alpha: 100,
  pixelSize: 10,
  precision: 'third'
};

const slice = createSlice({
  name: 'kriging',
  initialState,
  reducers: {
    setScale: (state, action: PayloadAction<'log' | 'linear'>) => {
      state.scale = action.payload;
    },
    setValueSelection: (state, action: PayloadAction<'min' | 'max' | 'avg' | 'last'>) => {
      state.valueSelection = action.payload;
    },
    setAlpha: (state, action: PayloadAction<number>) => {
      state.alpha = action.payload;
    },
    setModel: (state, action: PayloadAction<'gaussian' | 'exponential' | 'spherical' | 'linear'>) => {
      state.model = action.payload;
    },
    setVariance: (state, action: PayloadAction<number>) => {
      state.variance = action.payload;
    },
    setPixelSize: (state, action: PayloadAction<number>) => {
      state.pixelSize = action.payload;
    },
    setPrecision: (state, action: PayloadAction<'full' | 'half' | 'third'>) => {
      state.precision = action.payload;
    }
  }
});

export const { setScale, setValueSelection, setAlpha, setModel, setVariance, setPixelSize, setPrecision } =
  slice.actions;

export const { reducer } = slice;
