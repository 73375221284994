// API
import api from 'src/slices/api';

// Externals
import type { Action } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux';
import type { ThunkAction, ThunkDispatch } from 'redux-thunk';

// Reducers
import reducer from './reducer';

const store = configureStore({
  devTools: process.env.REACT_APP_ENABLE_REDUX_DEV_TOOLS === 'true',
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(api.middleware)
});

export type RootState = ReturnType<typeof store.getState>;

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
export type AppThunkDispatch = ThunkDispatch<RootState, unknown, Action<string>>;

export const useDispatch = () => useReduxDispatch<AppThunkDispatch>();

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export default store;
