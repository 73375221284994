import api from './api';

// Externals
import { createSlice } from '@reduxjs/toolkit';

//***************************************  STATE  ****************************************//

interface siteGroupState {}

const initialState: siteGroupState = {};

//**********************************  REQUEST & RESULT  **********************************//

export interface DataGroupListItem {
  name: string;
  id: string;
  pointsCount: number;
  parametersCount: number;
  membersCount: number;
}

interface GetSiteGroupsResult {
  dataGroups: DataGroupListItem[];
}

interface GetSiteGroupsRequest {
  siteId: number;
}

interface AddSiteGroupResult {}

interface AddSiteGroupRequest {
  siteId: number;
  name: string;
}

interface UpdateSiteGroupRequest {
  siteId: number;
  groupId: string;
  name: string;
  parameters: string[];
  points: string[];
  members: number[];
  aquiferThickness?: number | string;
  porosity?: number | string;
}

interface DeleteSiteGroupRequest {
  siteId: number;
  dataGroupId: string;
}

//*****************************************  API  *****************************************//

export const extendedSiteGroupsAPI = api
  .enhanceEndpoints({
    addTagTypes: ['site-groups']
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getSiteGroups: builder.query<GetSiteGroupsResult, GetSiteGroupsRequest>({
        query: (args) => ({
          url: `/sites/${args.siteId}/datagroups`,
          method: 'GET'
        }),
        providesTags: ['site-groups']
      }),

      addSiteGroup: builder.mutation<AddSiteGroupResult, AddSiteGroupRequest>({
        query: (args) => {
          return {
            url: `/sites/${args.siteId}/datagroups`,
            method: 'POST',
            body: { name: args.name }
          };
        },
        invalidatesTags: ['site-groups']
      }),

      updateSiteGroup: builder.mutation<void, UpdateSiteGroupRequest>({
        query: (args) => {
          return {
            url: `/sites/${args.siteId}/datagroups/${args.groupId}`,
            method: 'PUT',
            body: {
              name: args.name,
              parameters: args.parameters,
              points: args.points,
              members: args.members,
              aquiferThickness: args.aquiferThickness,
              porosity: args.porosity
            }
          };
        },
        invalidatesTags: ['site-groups']
      }),

      deleteSiteGroup: builder.mutation<void, DeleteSiteGroupRequest>({
        query: (args) => {
          return {
            url: `/sites/${args.siteId}/datagroups/${args.dataGroupId}`,
            method: 'DELETE'
          };
        },
        invalidatesTags: ['site-groups']
      })
    })
  });

//****************************************  SLICES  ****************************************//

export const slice = createSlice({
  name: 'site-group',
  initialState,
  reducers: {},
  extraReducers: (builder) => {}
});

//****************************************  THUNKS  ****************************************//

//****************************************  EXPORT  ****************************************//

export const { reducer } = slice;

export const {
  useGetSiteGroupsQuery,
  useAddSiteGroupMutation,
  useUpdateSiteGroupMutation,
  useDeleteSiteGroupMutation
} = extendedSiteGroupsAPI;
