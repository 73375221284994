import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface initialSettings {
  userId: string;
  siteId: number;
  tendancyAlertsValues: number[];
  zScoreAlertsValues: number[];
  isDifferentThanInitialState: boolean;
}

const initialState: initialSettings = {
  userId: '',
  siteId: 0,
  tendancyAlertsValues: [-20, -10, 10, 20],
  zScoreAlertsValues: [-5, -2, 2, 5],
  isDifferentThanInitialState: false
};

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    initializeSettings: (
      state,
      action: PayloadAction<{ userId: string; siteId: number; selectedParameter: string }>
    ) => {
      state.siteId = action.payload.siteId;
      state.userId = action.payload.userId;

      if (action.payload.selectedParameter) {
        const alertsSettings = JSON.parse(
          localStorage.getItem(
            `user-${state.userId}-site-${state.siteId}-parameter-${action.payload.selectedParameter}-alerts-settings`
          )
        );
        if (alertsSettings) {
          state.tendancyAlertsValues = alertsSettings.tendancyAlertsValues;
          state.zScoreAlertsValues = alertsSettings.zScoreAlertsValues;
          state.isDifferentThanInitialState = alertsSettings.tendancyAlertsValues !== initialState.tendancyAlertsValues;
        } else {
          state.tendancyAlertsValues = initialState.tendancyAlertsValues;
          state.zScoreAlertsValues = initialState.zScoreAlertsValues;
          state.isDifferentThanInitialState = false;
        }
      }
    },
    setTendancyValues(
      state,
      action: PayloadAction<{
        selectedParameter: string;
        tendancyAlertsValues: number[];
      }>
    ) {
      state.tendancyAlertsValues = action.payload.tendancyAlertsValues;
      state.isDifferentThanInitialState =
        JSON.stringify(state.tendancyAlertsValues) !== JSON.stringify(initialState.tendancyAlertsValues) ||
        JSON.stringify(state.zScoreAlertsValues) !== JSON.stringify(initialState.zScoreAlertsValues);

      //save to local storage
      localStorage.setItem(
        `user-${state.userId}-site-${state.siteId}-parameter-${action.payload.selectedParameter}-alerts-settings`,
        JSON.stringify({
          tendancyAlertsValues: state.tendancyAlertsValues,
          zScoreAlertsValues: state.zScoreAlertsValues
        })
      );
    },
    setZScoreValues(
      state,
      action: PayloadAction<{
        selectedParameter: string;
        zScoreAlertsValues: number[];
      }>
    ) {
      state.zScoreAlertsValues = action.payload.zScoreAlertsValues;
      state.isDifferentThanInitialState =
        JSON.stringify(state.tendancyAlertsValues) !== JSON.stringify(initialState.tendancyAlertsValues) ||
        JSON.stringify(state.zScoreAlertsValues) !== JSON.stringify(initialState.zScoreAlertsValues);

      localStorage.setItem(
        `user-${state.userId}-site-${state.siteId}-parameter-${action.payload.selectedParameter}-alerts-settings`,
        JSON.stringify({
          tendancyAlertsValues: state.tendancyAlertsValues,
          zScoreAlertsValues: state.zScoreAlertsValues
        })
      );
    },
    resetSettings(state, action: PayloadAction<string>) {
      state.tendancyAlertsValues = initialState.tendancyAlertsValues;
      state.zScoreAlertsValues = initialState.zScoreAlertsValues;
      state.isDifferentThanInitialState = false;

      localStorage.setItem(
        `user-${state.userId}-site-${state.siteId}-parameter-${action.payload}-alerts-settings`,
        JSON.stringify({
          tendancyAlertsValues: state.tendancyAlertsValues,
          zScoreAlertsValues: state.zScoreAlertsValues
        })
      );
    }
  }
});

export const { initializeSettings, setTendancyValues, setZScoreValues, resetSettings } = slice.actions;
export const { reducer } = slice;
