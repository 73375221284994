import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import api from './api';
import { Parameter, Point } from 'src/types/site-data.model';
import Layer from 'src/models/layer';
import moment from 'moment';

export interface Measure {
  id: number;
  date: Date;
  mesure: number;
  detectionLimit: number;
  underDetectionLimit: boolean;
  point: string;
  pointName: string;
  x: number;
  y: number;
  parameter: string;
  parameterName: string;
  units: string;
  analysisId: string;
  sampleId: string;
  laboratoryName: string;
}

export interface MinMaxResult {
  minAmount: number;
  maxAmount: number;
}

export type RangeType = 'week' | 'month' | 'quarter' | 'semester' | 'year' | 'custom';

export interface DateRange {
  startDate: string;
  endDate: string;
  range: RangeType;
}

/***** API ******/

interface GetPointsRequest {
  siteId: number;
  groupId?: string;
}

interface GetPointsResult {
  points: Point[];
}

interface GetParametersRequest {
  siteId: number;
  groupId?: string;
}

interface GetParametersResult {
  parameters: Parameter[];
}

interface GetMeasuresRequest {
  siteId: number;
  groupId?: string;
  parameterId?: string;
  pointId?: string;
  startDate?: string;
  endDate?: string;
}

interface GetMeasuresResult {
  mesures: Measure[];
}

interface GetMinMaxMeasuresRequest {
  siteId: number;
  groupId?: string;
  parameterId: string;
}

interface GetMinMaxMeasuresResult {
  minMax: MinMaxResult;
}

interface GetMapsRequest {
  siteId: number;
  extensions?: string;
}

interface GetMapsResult {
  layers: Layer[];
}

interface DeleteLayerRequest {
  siteId: number;
  layerId: number;
}

interface AddLayerRequest {
  filename: string;
  format: string;
  file: File;
  siteId: number;
  scaleX?: string;
  scaleY?: string;
  translateX?: string;
  translateY?: string;
  rotateX?: string;
  rotateY?: string;
  type: string;
}

export const extendedDashboardAPI = api
  .enhanceEndpoints({ addTagTypes: ['site-points', 'site-parameters', 'site-layers'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getPoints: builder.query<GetPointsResult, GetPointsRequest>({
        query: (args) => {
          return {
            url: `/sites/${args.siteId}/points`,
            method: 'GET',
            params: {
              groupId: args.groupId && args.groupId !== '0' ? args.groupId : undefined
            }
          };
        },
        providesTags: ['site-points']
      }),
      getParameters: builder.query<GetParametersResult, GetParametersRequest>({
        query: (args) => {
          return {
            url: `/sites/${args.siteId}/parameters`,
            method: 'GET',
            params: {
              groupId: args.groupId && args.groupId !== '0' ? args.groupId : undefined
            }
          };
        },
        providesTags: ['site-parameters']
      }),
      getMeasures: builder.query<GetMeasuresResult, GetMeasuresRequest>({
        query: (args) => {
          return {
            url: `/sites/${args.siteId}/measures`,
            method: 'GET',
            params: {
              groupId: args.groupId && args.groupId !== '0' ? args.groupId : undefined,
              parameterId: args.parameterId && args.parameterId !== 'none' ? args.parameterId : undefined,
              pointId: args.pointId && args.pointId !== 'none' ? args.pointId : undefined,
              afterDate: args.startDate || undefined,
              beforeDate: args.endDate || undefined
            }
          };
        }
      }),
      getMinMaxMeasures: builder.query<GetMinMaxMeasuresResult, GetMinMaxMeasuresRequest>({
        query: (args) => {
          return {
            url: `/sites/${args.siteId}/parameters/${args.parameterId}/measures/min-max`,
            method: 'GET',
            params: {
              groupId: args.groupId && args.groupId !== '0' ? args.groupId : undefined
            }
          };
        }
      }),
      getMaps: builder.query<GetMapsResult, GetMapsRequest>({
        query: (args) => ({
          url: `/sites/${args.siteId}/layers${args.extensions ? `?extensions=${args.extensions}` : ''}`,
          method: 'GET'
        }),
        providesTags: ['site-layers']
      }),
      addLayer: builder.mutation<void, AddLayerRequest>({
        query: (args) => {
          const formData = new FormData();
          formData.append('file', args.file);
          formData.append('filename', args.filename);
          formData.append('format', args.format);
          formData.append('scaleX', args.scaleX || '1');
          formData.append('scaleY', args.scaleY || '1');
          formData.append('translateX', args.translateX || '0');
          formData.append('translateY', args.translateY || '0');
          formData.append('rotateX', args.rotateX || '0');
          formData.append('rotateY', args.rotateY || '0');
          formData.append('type', args.type);

          return {
            url: `/sites/${args.siteId}/layers`,
            method: 'POST',
            body: formData,
            formData: true
          };
        },
        invalidatesTags: ['site-layers']
      }),
      deleteLayer: builder.mutation<void, DeleteLayerRequest>({
        query: (args) => ({
          url: `/sites/${args.siteId}/layers`,
          method: 'DELETE',
          body: {
            layerId: args.layerId
          }
        }),
        invalidatesTags: ['site-layers']
      })
    })
  });

/****** REDUCERS *******/

export interface IMapConfig {
  label: string;
  opacity: number;
  index: number;
  default: boolean;
}

interface ILayer {
  showAlerts: boolean;
  showPoints: boolean;
  showLabels: boolean;
  showKriging: boolean;
  maps: Layer[];
}

interface DashboardState {
  siteId: number;
  selectedPoints: string[];
  selectedPoint: string;
  selectedParameters: string[];
  selectedParameter: string;
  focusedPointData: { point: string; isGraphOpen: boolean };
  dateRange: DateRange;
  layers: ILayer;
}

const initialState: DashboardState = {
  siteId: 0,
  selectedPoints: [],
  selectedPoint: '',
  selectedParameters: [],
  selectedParameter: '',
  dateRange: {
    startDate: moment().startOf('year').toISOString(),
    endDate: moment().endOf('year').toISOString(),
    range: 'year'
  },
  focusedPointData: { point: '', isGraphOpen: false },
  layers: {
    showAlerts: false,
    showPoints: true,
    showLabels: true,
    showKriging: false,
    maps: [
      {
        opacity: 1,
        index: 0,
        label: 'points',
        default: true
      },
      {
        opacity: 1,
        index: 1,
        label: 'labels',
        default: true
      },
      {
        opacity: 1,
        index: 2,
        label: 'kriging',
        default: true
      }
    ]
  }
};

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setSelectedRange: (state, action: PayloadAction<DateRange>) => {
      state.dateRange = action.payload;
    },
    addPrimaryPoint: (state, action: PayloadAction<string>) => {
      state.selectedPoint = action.payload;
    },
    addPointToSelection: (state, action: PayloadAction<string>) => {
      // if point is already selected, return
      if (state.selectedPoints.includes(action.payload)) return;

      //if already 2 parameters, only keep the first one
      if (state.selectedParameters.length >= 2) {
        state.selectedParameters = [state.selectedParameters[0]];
      }

      // add point to selectedPoints
      state.selectedPoints.push(action.payload);
    },
    addParameterToSelection: (state, action: PayloadAction<string>) => {
      // if parameter is already selected, return
      if (state.selectedParameters.includes(action.payload)) return;

      // if number of selected parameter >=1, keep the first point in selectedPoints
      if (state.selectedParameters.length >= 1 && state.selectedPoints.length >= 1) state.selectedPoints = [];

      // if 2 parameters selected, remove the second one
      if (state.selectedParameters.length >= 2) state.selectedParameters.pop();

      // add parameter to selectedParameters
      state.selectedParameters.push(action.payload);
    },
    removeParameterFromSelection: (state, action: PayloadAction<string>) => {
      state.selectedParameters = state.selectedParameters.filter((p) => p !== action.payload);
    },
    removePrimaryPoint: (state) => {
      state.selectedPoint = null;
    },
    removePointFromSelection: (state, action: PayloadAction<string>) => {
      state.selectedPoints = state.selectedPoints.filter((p) => p !== action.payload);
    },
    resetSelection: (state) => {
      state.selectedPoints = [];
      state.selectedParameters = [];
    },
    resetPointsSelection: (state) => {
      state.selectedPoints = [];
    },
    resetParametersSelection: (state) => {
      state.selectedParameters = [];
    },
    setFocusedPointData: (
      state,
      action: PayloadAction<{
        point: string;
        isGraphOpen: boolean;
      }>
    ) => {
      state.focusedPointData = action.payload;
    },
    toggleShowAlerts: (state) => {
      state.layers.showAlerts = !state.layers.showAlerts;
      localStorage.setItem(`site-${state.siteId}-layers-order`, JSON.stringify(state.layers));
    },
    toggleShowKriging: (state) => {
      state.layers.showKriging = !state.layers.showKriging;
      localStorage.setItem(`site-${state.siteId}-layers-order`, JSON.stringify(state.layers));
    },
    initializeLayersSite: (
      state,
      action: PayloadAction<{
        siteId: number;
        layers: Layer[];
      }>
    ) => {
      if (state.siteId !== action.payload.siteId) {
        // Reset state to initial state
        Object.assign(state, initialState);
      }

      // Retrieve layers from localStorage or use initial layers
      const layersFromLocalStorage =
        JSON.parse(localStorage.getItem(`site-${action.payload.siteId}-layers-order`)) || initialState.layers;

      // Initialize an array to store layers to be saved
      const layersToSave: Layer[] = [];

      // Iterate through layers from local storage
      for (const layer of layersFromLocalStorage.maps) {
        if (!layer.default) {
          // Find corresponding layer from API
          const layerFromApi = action.payload.layers.find((l) => l.label === layer.label);
          if (layerFromApi) {
            layersToSave.push({
              opacity: layer.opacity,
              default: layer.default,
              ...layerFromApi
            });
          }
        } else {
          layersToSave.push({
            opacity: layer.opacity,
            default: layer.default,
            index: layer.index,
            label: layer.label
          });
        }
      }

      // Add new layers from API if not found in local storage
      for (const layer of action.payload.layers) {
        if (!layersFromLocalStorage.maps.find((l) => l.label === layer.label)) {
          layersToSave.push({
            opacity: 1,
            default: false,
            ...layer
          });
        }
      }

      // Update state with a new object for layers
      state.layers = { ...state.layers, maps: layersToSave };
      state.siteId = action.payload.siteId;

      // Save updated layers to localStorage
      localStorage.setItem(`site-${state.siteId}-layers-order`, JSON.stringify(state.layers));
    },
    setLayerOpacity: (state, action: PayloadAction<{ opacity: number; label: string }>) => {
      state.layers.maps = state.layers.maps.map((m) => {
        if (m.label === action.payload.label) {
          return { ...m, opacity: action.payload.opacity };
        }
        return m;
      });
      localStorage.setItem(`site-${state.siteId}-layers-order`, JSON.stringify(state.layers));
    },

    reorderMaps: (state, action: PayloadAction<IMapConfig[]>) => {
      state.layers.maps = action.payload;
      localStorage.setItem(`site-${state.siteId}-layers-order`, JSON.stringify(state.layers));
    }
  }
});

export const setSelectedRange =
  (dateRange: { startDate: string; endDate: string; range: RangeType }): AppThunk =>
    async (dispatch): Promise<void> => {
      try {
        dispatch(slice.actions.setSelectedRange(dateRange));
      } catch (error) { }
    };

export const { reducer } = slice;

export const {
  addPointToSelection,
  addPrimaryPoint,
  removePointFromSelection,
  removePrimaryPoint,
  addParameterToSelection,
  removeParameterFromSelection,
  resetPointsSelection,
  resetParametersSelection,
  setFocusedPointData,
  resetSelection,
  toggleShowAlerts,
  toggleShowKriging,
  initializeLayersSite,
  reorderMaps,
  setLayerOpacity
} = slice.actions;

export const {
  useGetPointsQuery,
  useGetParametersQuery,
  useGetMeasuresQuery,
  useGetMinMaxMeasuresQuery,
  useGetMapsQuery,
  useAddLayerMutation,
  useDeleteLayerMutation
} = extendedDashboardAPI;
