import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import Navbar from './navbar';
import Sidebar from './sidebar';
import { experimentalStyled } from '@mui/material';
import { Outlet } from 'react-router-dom';
interface LayoutProps {
  children?: ReactNode;
}

const LayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  overflow: 'hidden',
  height: '100vh',
  width: '100%'
}));

const LayoutWrapper = experimentalStyled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: '64px'
}));

const LayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const LayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch'
});

const DashboardLayout: FC<LayoutProps> = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <LayoutRoot>
      <Navbar onOpen={(): void => setIsOpen(true)} />
      <LayoutWrapper>
        <Sidebar onClose={(): void => setIsOpen(false)} isOpen={isOpen} />
        <LayoutContainer>
          <LayoutContent>
            <Outlet />
          </LayoutContent>
        </LayoutContainer>
      </LayoutWrapper>
    </LayoutRoot>
  );
};

export default DashboardLayout;
