// Externals
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

// Store
import type { AppThunk } from 'src/store';

// Services
import { getOrganizationMembers as getOrganizationMembersApi } from 'src/services/members/getOrganizationMembers';

// Models
import { OrganizationMemberDto } from 'src/services/members/getOrganizationMembers/models/OrganizationMemberDto';
import { SiteDto } from 'src/services/sites/getSite/models/SiteDto';
import api from './api';

//***************************************  STATE  ****************************************//

export interface OrganizationState {
  members: OrganizationMemberDto[];
  manageSite: SiteDto;
  organizations: GetMembersResponse;
}

const initialState: OrganizationState = {
  members: [],
  manageSite: null,
  organizations: null
};

//**********************************  REQUEST & RESULT  **********************************//

interface MembersGroupDto {
  id: string;
  name: string;
}

interface MembersSitesDto {
  id: number;
  name: string;
  groups: MembersGroupDto[];
  role: 'user' | 'guest';
}

interface MembersOrganizationsDto {
  id: number;
  name: string;
  role: 'administrator' | 'user';
  sites: MembersSitesDto[];
}

export interface GetMembersResponse {
  organizations: MembersOrganizationsDto[];
}

interface GetMembersRequest {}

//*****************************************  API  *****************************************//

const slice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setMembers(state: OrganizationState, action: PayloadAction<OrganizationMemberDto[]>): void {
      state.members = action.payload;
    },
    reset: () => initialState
  },
  extraReducers: (builder) => {
    builder.addMatcher(extendedOrganizationAPI.endpoints.getMembersData.matchFulfilled, (state, { payload }) => {
      state.organizations = payload;
    });
  }
});

//*****************************************  API  *****************************************//

export const extendedOrganizationAPI = api
  .enhanceEndpoints({
    addTagTypes: ['members-data-me']
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getMembersData: builder.query<GetMembersResponse, GetMembersRequest>({
        query: () => ({ url: '/members/mine', method: 'GET' }),
        providesTags: ['members-data-me']
      })
    })
  });

export const getOrganizationMembers =
  (organizationId): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const response = await getOrganizationMembersApi({ organizationId: organizationId });
      await dispatch(slice.actions.setMembers(response.members));
    } catch (error) {
      await dispatch(slice.actions.setMembers([]));
    }
  };

// Export slice and actions
export const { reducer } = slice;
export const { reset } = slice.actions;

export const { useGetMembersDataQuery } = extendedOrganizationAPI;
