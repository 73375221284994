// API
import api from '../slices/api';

// Externals
import { combineReducers } from '@reduxjs/toolkit';

// Slices
import { reducer as authenticationReducer } from '../slices/authentication';
import { reducer as organizationReducer } from '../slices/organization';
import { reducer as permissionsReducer } from '../slices/permissions';
import { reducer as siteDataReducer } from '../slices/importdata';
import { reducer as siteGroupReducer } from '../slices/group';
import { reducer as dashboardReducer } from '../slices/dashboard';
import { reducer as krigingReducer } from '../slices/kriging';
import { reducer as tableNavigationReducer } from '../slices/table-navigation';
import { reducer as settingsReducer } from '../slices/settings';

const reducer = combineReducers({
  authentication: authenticationReducer,
  organization: organizationReducer,
  permissions: permissionsReducer,
  siteData: siteDataReducer,
  siteGroupData: siteGroupReducer,
  dashboard: dashboardReducer,
  tableNavigation: tableNavigationReducer,
  kriging: krigingReducer,
  settings: settingsReducer,
  [api.reducerPath]: api.reducer
});

export default reducer;
