import { ElementType, type FC, useEffect } from 'react';

// Externals
import { AppBar, Grid, MenuItem, Select, Toolbar, useTheme } from '@mui/material';
import PropTypes from 'prop-types';

// Components
import NavOptions from 'src/Components/popovers/navOptions';
import Lang from 'src/lang';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'src/store';
import useOrganizationId from 'src/hooks/useOrganizationId';
import useSiteId from 'src/hooks/useSiteId';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import useGroupId from 'src/hooks/useGroupId';
import { GetMembersResponse } from 'src/slices/organization';
import { Trans } from '@lingui/macro';
import { useSiteRole } from 'src/hooks/use-role';
import { resetSelection } from 'src/slices/dashboard';

export const getFirstSiteId = (data: GetMembersResponse, organizationId: number) => {
  const site = data?.organizations.find((org) => org.id === organizationId).sites[0];
  return site.id;
};

export const getFirstGroupId = (data: GetMembersResponse, organizationId: number, siteId: number) => {
  const site = data.organizations.find((org) => org.id === organizationId).sites.find((site) => site.id === siteId);
  const siteRole = site.role;
  if (siteRole === 'user') {
    return '0';
  } else {
    return site.groups[0].id;
  }
};

interface AdminNavbarProps {
  onOpen?: () => void;
}

const Navbar: FC<AdminNavbarProps> = ({ onOpen }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const organizationId = useOrganizationId();
  const siteId = useSiteId();
  const groupId = useGroupId();

  const data = useSelector((state) => state.organization.organizations);
  const siteRole = useSiteRole();

  useEffect(() => {
    if (siteRole === 'guest' && groupId === '0') {
      navigate(`/${organizationId}/${siteId}/${getFirstGroupId(data, organizationId, siteId)}/map`);
    }
  }, [data, groupId, navigate, organizationId, siteRole, siteId]);

  const selectStyle = {
    color: theme.palette.primary.contrastText,
    fontSize: '1rem',
    border: 'none',
    '& .MuiSelect-icon': {
      color: theme.palette.primary.contrastText
    },

    boxShadow: 'none',
    borderRadius: 0,
    '.MuiOutlinedInput-notchedOutline': { border: 0 }
  };

  const getGroupSelect = (organizationId: number, siteId: number) => {
    const groups = data?.organizations
      ?.find((org) => org.id === organizationId)
      ?.sites.find((site) => site.id === siteId).groups;
    const isGroupEmpty = groups?.length === 0;

    const siteRole = data?.organizations
      .find((org) => org.id === organizationId)
      ?.sites?.find((site) => site.id === siteId).role;

    type selectProps = {
      readOnly: boolean;
      value: string | number;
      IconComponent: ElementType;
    };

    const isEmptyProps = {
      readOnly: true,
      value: '0',
      IconComponent: () => <></>
    };

    return (
      <>
        <ArrowForwardIosIcon
          fontSize="small"
          sx={{
            fontSize: '0.75rem'
          }}
        />

        <Select
          value={groupId}
          sx={selectStyle}
          onChange={(e) => onGroupChange(e.target.value as string)}
          {...((isGroupEmpty ? isEmptyProps : {}) as selectProps)}
        >
          {siteRole === 'user' && (
            <MenuItem value="0">
              <Trans>All Data</Trans>
            </MenuItem>
          )}
          {groups &&
            groups.length > 0 &&
            groups.map((group) => (
              <MenuItem key={group.id} value={group.id}>
                {group.name}
              </MenuItem>
            ))}
        </Select>
      </>
    );
  };

  const onOrganizationChange = (value: number | string) => {
    // invalidate siteId and groupId
    const newOrganizationId = value as number;
    const newSiteId = getFirstSiteId(data, newOrganizationId);
    const newGroupId = getFirstGroupId(data, value as number, newSiteId);
    navigate(`/${value}/${newSiteId}/${newGroupId}/map`);
    dispatch(resetSelection());
  };

  const onSiteChange = (value: number | string) => {
    // invalidate groupId
    const newSiteId = value as number;
    const firstGroupId = getFirstGroupId(data, organizationId, newSiteId);
    navigate(`/${organizationId}/${value}/${firstGroupId}/map`);
    dispatch(resetSelection());
  };

  const onGroupChange = (value: number | string) => {
    navigate(`/${organizationId}/${siteId}/${value}/map`);
  };

  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: theme.palette.primary.main,
        boxShadow: 'none',
        color: theme.palette.primary.contrastText,
        zIndex: 100
      }}
    >
      <Toolbar sx={{ minHeight: 64 }} style={{ paddingLeft: 8, paddingRight: 8 }}>
        <Grid
          container={true}
          direction="row"
          wrap="nowrap"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid item={true} container={true} direction="row" wrap="nowrap" alignItems="center" justifyContent="start">
            <img
              src="/logo.png"
              alt="logo"
              style={{ height: 30, marginLeft: 10, cursor: 'pointer' }}
              onClick={() => navigate('/')}
            />
            <Grid item={true} container={true} direction="row" alignItems="center">
              {data?.organizations && (
                <>
                  <Select
                    value={organizationId}
                    sx={selectStyle}
                    onChange={(e) => onOrganizationChange(e.target.value)}
                  >
                    {data?.organizations?.map((organization) => (
                      <MenuItem key={organization.id} value={organization.id}>
                        {organization.name}
                      </MenuItem>
                    ))}
                  </Select>

                  {siteId !== null && data.organizations?.find((org) => org.id === organizationId)?.sites && (
                    <>
                      <ArrowForwardIosIcon
                        fontSize="small"
                        sx={{
                          fontSize: '0.75rem'
                        }}
                      />

                      <Select value={siteId} sx={selectStyle} onChange={(e) => onSiteChange(e.target.value)}>
                        {data.organizations
                          ?.find((org) => org.id === organizationId)
                          ?.sites?.map((site) => (
                            <MenuItem key={site.id} value={site.id}>
                              {site.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </>
                  )}
                  {groupId !== null && getGroupSelect(organizationId, siteId)}
                </>
              )}
            </Grid>
          </Grid>
          <Grid
            item={true}
            container={true}
            direction="row"
            justifyContent="end"
            alignItems="center"
            wrap="nowrap"
            spacing={2}
          >
            <Grid item={true}>
              <Lang />
            </Grid>
            <Grid item={true}>
              <NavOptions />
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

Navbar.propTypes = {
  onOpen: PropTypes.func
};

export default Navbar;
