import { useEffect, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'src/store';
import { useGetMembersDataQuery } from 'src/slices/organization';
import useSiteId from 'src/hooks/useSiteId';
import useGroupId from 'src/hooks/useGroupId';
import useOrganizationId from 'src/hooks/useOrganizationId';
import SplashScreen from 'src/screens/SplashScreen';

export const saveRouteToLocalStorage = ({
  userId,
  organizationId,
  siteId,
  groupId
}: {
  userId: string;
  organizationId: string;
  siteId: string;
  groupId: string;
}): void => {
  window.localStorage.setItem(`${userId}-organization`, organizationId);
  window.localStorage.setItem(`${userId}-site`, siteId);
  window.localStorage.setItem(`${userId}-group`, groupId);
};

/**
 * Ensure that the user can access the organization site and group that are provided through the URL.
 * Otherwise, redirects to default route "/"
 * @param children the child component
 * @returns
 */
export const RouteSiteGuard = ({ children }) => {
  const userId = useSelector((state) => state.authentication.user?.id);

  const organizationId = useOrganizationId();
  const siteId = useSiteId();
  const groupId = useGroupId();

  const { isFetching } = useGetMembersDataQuery({}, { refetchOnMountOrArgChange: true });
  const organizations = useSelector(
    (state) => state.organization && state.organization.organizations && state.organization.organizations.organizations
  );

  const foundOrganization = organizations?.find((organization) => organization.id === organizationId);
  const foundSite = foundOrganization?.sites.find((site) => site.id === siteId);
  const foundGroup = useMemo(() => {
    return groupId === '0' ? { id: '0' } : foundSite?.groups.find((g) => g.id === groupId);
  }, [foundSite?.groups, groupId]);

  useEffect(() => {
    if (foundGroup && foundSite && foundOrganization && userId) {
      saveRouteToLocalStorage({
        userId,
        organizationId: foundOrganization.id.toString(),
        siteId: foundSite.id.toString(),
        groupId: foundGroup.id.toString()
      });
    }
  }, [foundOrganization, foundSite, foundGroup, userId]);

  if (isFetching) {
    return <SplashScreen />;
  }

  if ((groupId && !foundGroup) || (siteId && !foundSite) || (organizationId && !foundOrganization)) {
    return <Navigate replace={true} to={`/`} />;
  }

  return <>{children}</>;
};
