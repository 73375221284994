/* eslint-disable no-unreachable */
import React, { FC } from 'react';

// Externals
import { Grid, Link } from '@mui/material';

// Hooks
import { useLingui } from '@lingui/react';

const Lang: FC = () => {
  return <></>; // This return statement is here to hide lang switcher
  const { i18n } = useLingui();

  const LOCALES = ['en', 'fr'];

  const handleLanguageSelected = (languageCode: string) => {
    i18n.activate(languageCode);
    localStorage.setItem('lang', languageCode);
  };

  return (
    <Grid container={true} direction="row" justifyContent="end" alignItems="center" spacing={1} wrap="nowrap">
      {LOCALES.map((locale, index) => (
        <React.Fragment key={locale}>
          <Grid item={true} key={locale}>
            <Link
              disabled={i18n.locale === locale}
              color="white"
              component="button"
              onClick={() => handleLanguageSelected(locale)}
              underline="none"
              sx={{ opacity: i18n.locale === locale ? 1 : 0.4 }}
            >
              {locale.toUpperCase()}
            </Link>
          </Grid>
          {index < LOCALES.length - 1 && <Grid item={true}>/</Grid>}
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default Lang;
