import type { FC } from 'react';

// Externals
import { AppBar, experimentalStyled, Grid, Toolbar } from '@mui/material';
import PropTypes from 'prop-types';

// Components
import NavOptions from 'src/Components/popovers/navOptions';
import Lang from 'src/lang';
import { useNavigate } from 'react-router';

const AdministrationNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  boxShadow: 'none',
  color: theme.palette.primary.contrastText
}));

const AdministrationNavbar: FC = () => {
  const navigate = useNavigate();

  return (
    <AdministrationNavbarRoot elevation={0}>
      <Toolbar sx={{ minHeight: 64 }}>
        <img src="/logo.png" alt="logo" style={{ height: 30, cursor: 'pointer' }} onClick={() => navigate('/')} />
        <Grid
          container={true}
          direction="row"
          wrap="nowrap"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid
            item={true}
            container={true}
            direction="row"
            justifyContent="end"
            alignItems="center"
            wrap="nowrap"
            spacing={2}
          >
            <Grid item={true}>
              <Lang />
            </Grid>
            <Grid item={true}>
              <NavOptions />
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AdministrationNavbarRoot>
  );
};

AdministrationNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default AdministrationNavbar;
